<template>
  <v-container>
    <v-container>
      heaa
      <v-card>
        <v-card-title>
          $vuetify.breakpoint.name: {{ $vuetify.breakpoint.name }}
        </v-card-title>
      </v-card>
    </v-container>
    <v-container>
      <v-card class="pa-3" :height="height1"> 콘덴스 </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  computed: {
    height1() {
      switch (this.$vuetify.breakpoint.name) {
        case "sx":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 300;
        default:
          return 500;
      }
    },
  },
};
</script>

<style></style>
